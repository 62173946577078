<template>
    <div>
        <div class="page-header justify-content-start marb0">
            <lable class="page-title ">Task</lable>
            <!-- <ul class="breadcrumbs">
                <li>Raj Sinha > Pending Task (04)</li>
            </ul> -->
        </div>
        <div class="task-filters">
            <ul>
                <li v-on:click="isActive='ToMe'" v-bind:class="{ active: isActive=='ToMe' }" ><p>Assigned To Me <span>(20)</span></p></li>
                <li v-on:click="isActive='ByMe'" v-bind:class="{ active: isActive=='ByMe' }"><p>Assigned By Me <span>(30)</span></p></li>
                <li v-on:click="isActive='Hold'" v-bind:class="{ active: isActive=='Hold' }"><p>On Hold <span>(4)</span></p></li>
                <li v-on:click="isActive='Archive'" v-bind:class="{ active: isActive=='Archive' }"><p>Archive <span>(50)</span></p></li>
            </ul>
        </div>
        <section class="tasks-section">
            <div class="tasks-header">
                <div class="task-filters-left">
                    <ul>
                        <li v-on:click="isActive='All'" v-bind:class="{ active: isActive=='All' }">All</li>
                        <li v-on:click="isActive='High'" v-bind:class="{ active: isActive=='High' }" >High</li>
                        <li v-on:click="isActive='Medium'" v-bind:class="{ active: isActive=='Medium' }" >Medium</li>
                        <li v-on:click="isActive='Low'" v-bind:class="{ active: isActive=='Low' }" >Low</li>
                    </ul>
                </div>
                <div class="task-filters-right">
                    <ul>
                        <li>
                            <label>CATEGORY</label>
                            <multiselect
                                v-model="categorySelected"
                                :show-labels="false" 
                                :options="categoryOptions">
                            </multiselect>
                        </li>
                        <li>
                            <label>SUB - CATEGORY</label> 
                            <multiselect
                                v-model="subCategorySelected" 
                                :show-labels="false" 
                                :options="subCategoryOptions">
                            </multiselect>
                        </li>
                        <li>
                            <label>TIME RANGE</label> 
                            <div class="date_range">
                                <dateRange></dateRange>                                 
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
             
            <div class="task-wrap">
                <div class="task-list high">
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         <li class="priority">
                            <div class="task-item">
                                <label>PRIORITY</label>
                                <div class="task-content"><p>High</p></div>
                            </div>
                            <div class="task-item">
                                <label>ATTACHMENT</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small v-b-modal.attachments>1</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content">
                                    <div class="popover_tabs_wrap">       
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Take Action</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/flag.svg"></figure>
                                                            <em>Flag - <br/>Urgent</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/query.svg" >
                                                                </figure>
                                                            <em>Raise <br/>Query</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.09091 1L1 8.09091V17.9091L8.09091 25H17.9091L25 17.9091V8.09091L17.9091 1H8.09091Z" stroke="#58508D" stroke-width="1.5"/>
                                                                    <path d="M15.7473 8.38463V12.3846C15.7473 12.5545 15.6095 12.6923 15.4397 12.6923C15.2698 12.6923 15.132 12.5545 15.132 12.3846V6.53848H13.2858V11.7692C13.2858 11.9391 13.148 12.0769 12.9781 12.0769C12.8083 12.0769 12.6704 11.9391 12.6704 11.7692V5.9231H10.8243V11.7692C10.8243 11.9391 10.6864 12.0769 10.5166 12.0769C10.3467 12.0769 10.2089 11.9391 10.2089 11.7692V7.15386H8.36273V14.8462L7.11658 13.1409C6.74735 12.5717 6.02673 12.3809 5.49873 12.7071C4.97258 13.0406 4.84212 13.768 5.20642 14.3354C5.20642 14.3354 7.21627 17.3772 8.07289 18.6794C8.9295 19.9816 10.3172 21 12.9123 21C17.2089 21 17.5935 17.6819 17.5935 16.6923C17.5935 15.7028 17.5935 8.38463 17.5935 8.38463H15.7473Z" fill="#58508D"/>
                                                                </svg>

                                                            </figure>
                                                            <em>On Hold</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/archive.svg"></figure>
                                                            <em>Archive</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/Icon_user.svg"></figure>
                                                            <em>Assign</em>                                                 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/reply.svg"></figure>
                                                            <em>Reply</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>
                                                    
                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/approve.png"></figure>
                                                            <em>Approve</em> 
                                                        </template> 
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                            </div>
                                        </popper>
                                    </div>
                                </div> 
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"> 
                                    <div class="popper_cnt">
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            Activity Log
                                            </button>
                                            <div class="popper">
                                                <div class="popper_head">
                                                    <h5>Activity Log</h5>
                                                </div>
                                                <div class="activity_table">
                                                    <table>
                                                        <thead>                                                        
                                                            <tr>
                                                                <th>actioned on</th>
                                                                <th>Action Type</th>
                                                                <th>TASK ASSIGNED BY</th>
                                                                <th>TASK ASSIGNED TO</th>
                                                                <th>comments</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>4th Nov ‘20 <span>01:04 am</span></td>
                                                                <td>Approved</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Raj Sinha</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>-</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3rd Nov ‘20<span>04:04 pm</span></td>
                                                                <td>Approved</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Sai Pallavi</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Raj Sinha</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2nd Nov ‘20<span>10:15 am</span></td>
                                                                <td>Flag - Urgent</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Hema Dharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>1st Nov ‘20<span>11:04 am</span></td>
                                                                <td>Created</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Pooja Radhakrishna</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </popper>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list medium"> 
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         <li class="priority">
                            <div class="task-item">
                                <label>PRIORITY</label>
                                <div class="task-content"><p>Medium</p></div>
                            </div>
                            <div class="task-item">
                                <label>ATTACHMENT</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small v-b-modal.attachments>1</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                
                                <label><br/></label>
                                <div class="task-content">
                                    <div class="popover_tabs_wrap">       
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Take Action</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/flag.svg"></figure>
                                                            <em>Flag - <br/>Urgent</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/query.svg" >
                                                                </figure>
                                                            <em>Raise <br/>Query</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.09091 1L1 8.09091V17.9091L8.09091 25H17.9091L25 17.9091V8.09091L17.9091 1H8.09091Z" stroke="#58508D" stroke-width="1.5"/>
                                                                    <path d="M15.7473 8.38463V12.3846C15.7473 12.5545 15.6095 12.6923 15.4397 12.6923C15.2698 12.6923 15.132 12.5545 15.132 12.3846V6.53848H13.2858V11.7692C13.2858 11.9391 13.148 12.0769 12.9781 12.0769C12.8083 12.0769 12.6704 11.9391 12.6704 11.7692V5.9231H10.8243V11.7692C10.8243 11.9391 10.6864 12.0769 10.5166 12.0769C10.3467 12.0769 10.2089 11.9391 10.2089 11.7692V7.15386H8.36273V14.8462L7.11658 13.1409C6.74735 12.5717 6.02673 12.3809 5.49873 12.7071C4.97258 13.0406 4.84212 13.768 5.20642 14.3354C5.20642 14.3354 7.21627 17.3772 8.07289 18.6794C8.9295 19.9816 10.3172 21 12.9123 21C17.2089 21 17.5935 17.6819 17.5935 16.6923C17.5935 15.7028 17.5935 8.38463 17.5935 8.38463H15.7473Z" fill="#58508D"/>
                                                                </svg>

                                                            </figure>
                                                            <em>On Hold</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/archive.svg"></figure>
                                                            <em>Archive</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/Icon_user.svg"></figure>
                                                            <em>Assign</em>                                                 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/reply.svg"></figure>
                                                            <em>Reply</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>
                                                    
                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/approve.png"></figure>
                                                            <em>Approve</em> 
                                                        </template> 
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                            </div>
                                        
                                        
                                        </popper>
                                    </div>
                                </div>   
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content popper_cnt"> 
                                    <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                        <button slot="reference" class="takeaction">
                                        Activity Log
                                        </button>
                                        <div class="popper">
                                            <div class="popper_head">
                                                <h5>Activity Log</h5>
                                            </div>
                                            <div class="activity_table">
                                                <table>
                                                    <thead>                                                        
                                                        <tr>
                                                            <th>actioned on</th>
                                                            <th>Action Type</th>
                                                            <th>TASK ASSIGNED BY</th>
                                                            <th>TASK ASSIGNED TO</th>
                                                            <th>comments</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>4th Nov ‘20 <span>01:04 am</span></td>
                                                            <td>Approved</td>
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Rohit Sharma</figcaption>
                                                                </div>
                                                            </td>
                                                            
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Raj Sinha</figcaption>
                                                                </div>
                                                            </td>
                                                            <td>-</td>
                                                        </tr>
                                                        <tr>
                                                            <td>3rd Nov ‘20<span>04:04 pm</span></td>
                                                            <td>Approved</td>
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Sai Pallavi</figcaption>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Raj Sinha</figcaption>
                                                                </div>
                                                            </td>
                                                            <td> 
                                                                <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2nd Nov ‘20<span>10:15 am</span></td>
                                                            <td>Flag - Urgent</td>
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Rohit Sharma</figcaption>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Hema Dharma</figcaption>
                                                                </div>
                                                            </td>
                                                            <td> 
                                                                <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                            </td>
                                                        </tr>
                                                         <tr>
                                                            <td>1st Nov ‘20<span>11:04 am</span></td>
                                                            <td>Created</td>
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Rohit Sharma</figcaption>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div class="activity_profile">
                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                    <figcaption>Pooja Radhakrishna</figcaption>
                                                                </div>
                                                            </td>
                                                            <td> 
                                                                <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </popper>
                                
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list low">
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         <li class="priority">
                            <div class="task-item">
                                <label>PRIORITY</label>
                                <div class="task-content"><p>Low</p></div>
                            </div>
                            <div class="task-item">
                                <label>ATTACHMENT</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small v-b-modal.attachments>1</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content">
                                    <div class="popover_tabs_wrap">       
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Take Action</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/flag.svg"></figure>
                                                            <em>Flag - <br/>Urgent</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/query.svg" >
                                                                </figure>
                                                            <em>Raise <br/>Query</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.09091 1L1 8.09091V17.9091L8.09091 25H17.9091L25 17.9091V8.09091L17.9091 1H8.09091Z" stroke="#58508D" stroke-width="1.5"/>
                                                                    <path d="M15.7473 8.38463V12.3846C15.7473 12.5545 15.6095 12.6923 15.4397 12.6923C15.2698 12.6923 15.132 12.5545 15.132 12.3846V6.53848H13.2858V11.7692C13.2858 11.9391 13.148 12.0769 12.9781 12.0769C12.8083 12.0769 12.6704 11.9391 12.6704 11.7692V5.9231H10.8243V11.7692C10.8243 11.9391 10.6864 12.0769 10.5166 12.0769C10.3467 12.0769 10.2089 11.9391 10.2089 11.7692V7.15386H8.36273V14.8462L7.11658 13.1409C6.74735 12.5717 6.02673 12.3809 5.49873 12.7071C4.97258 13.0406 4.84212 13.768 5.20642 14.3354C5.20642 14.3354 7.21627 17.3772 8.07289 18.6794C8.9295 19.9816 10.3172 21 12.9123 21C17.2089 21 17.5935 17.6819 17.5935 16.6923C17.5935 15.7028 17.5935 8.38463 17.5935 8.38463H15.7473Z" fill="#58508D"/>
                                                                </svg>

                                                            </figure>
                                                            <em>On Hold</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/archive.svg"></figure>
                                                            <em>Archive</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/Icon_user.svg"></figure>
                                                            <em>Assign</em>                                                 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/reply.svg"></figure>
                                                            <em>Reply</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>
                                                    
                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/approve.png"></figure>
                                                            <em>Approve</em> 
                                                        </template> 
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                            </div>
                                        
                                        
                                        </popper>
                                    </div>
                                </div>    
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content "> 
                                    <div class="popper_cnt">
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            Activity Log
                                            </button>
                                            <div class="popper">
                                                <div class="popper_head">
                                                    <h5>Activity Log</h5>
                                                </div>
                                                <div class="activity_table">
                                                    <table>
                                                        <thead>                                                        
                                                            <tr>
                                                                <th>actioned on</th>
                                                                <th>Action Type</th>
                                                                <th>TASK ASSIGNED BY</th>
                                                                <th>TASK ASSIGNED TO</th>
                                                                <th>comments</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>4th Nov ‘20 <span>01:04 am</span></td>
                                                                <td>Approved</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Raj Sinha</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>-</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3rd Nov ‘20<span>04:04 pm</span></td>
                                                                <td>Approved</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Sai Pallavi</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Raj Sinha</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2nd Nov ‘20<span>10:15 am</span></td>
                                                                <td>Flag - Urgent</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Hema Dharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>1st Nov ‘20<span>11:04 am</span></td>
                                                                <td>Created</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Pooja Radhakrishna</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </popper>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="task-list high">
                    <ul>
                        <li class="category">
                            <div class="task-item">
                                <label>CATEGORY</label>
                                <div class="task-content"><span>administration</span></div>
                            </div>
                            <div class="task-item">
                                <label>SUB - CATEGORY</label>
                                <div class="task-content"><small>Finance</small></div>
                            </div>
                        </li>
                        <li class="subject">
                            <div class="task-item">
                                <label>SUBJECT</label>
                                <div class="task-content"><strong>Kanha Budget 2020 Review - 2nd Version Approval</strong></div>
                            </div>
                            <div class="task-item">
                                <label>DESCRIPTION</label>
                                <div class="task-content">
                                    <div class="description"><p>Attached is Kanha budet 2020. Need your approval on the same for further</p></div>
                                </div>
                            </div>
                        </li>
                        <li class="owner">
                            <div class="task-item">
                                <label>Task OWNER</label>
                                <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Pooja Radhakrishna</figcaption>
                                    </div>
                                </div>
                            </div>
                            <div class="task-item">
                                <label>Task ASSIGNED BY</label>
                                 <div class="task-content">
                                    <div class="profile-sec">
                                        <figure><img src="@/assets/images/user.png"></figure>
                                        <figcaption>Prakash Jha</figcaption>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="create">
                            <div class="task-item">
                                <label>Created on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                            <div class="task-item">
                                <label>Assigned on</label>
                                <div class="task-content"><p>1st Nov '20 <br/>11:04 am</p></div>
                            </div>
                        </li>
                         <li class="priority">
                            <div class="task-item">
                                <label>PRIORITY</label>
                                <div class="task-content"><p>High</p></div>
                            </div>
                            <div class="task-item">
                                <label>ATTACHMENT</label>
                                <div class="task-content">
                                    <div class="attachments">
                                        <small v-b-modal.attachments>1</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="task-actions">
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content"> 
                                    <div class="popover_tabs_wrap">      
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            TAKE ACTION
                                            </button>
                                            <div class="popper">
                                                <div class="popover_tabs">
                                                <div class="popover_tab_title">
                                                    <h5>Take Action</h5>
                                                </div>
                                                <b-tabs>
                                                    <b-tab active>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/flag.svg"></figure>
                                                            <em>Flag - <br/>Urgent</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <img src="@/assets/images/query.svg" >
                                                                </figure>
                                                            <em>Raise <br/>Query</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure>
                                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M8.09091 1L1 8.09091V17.9091L8.09091 25H17.9091L25 17.9091V8.09091L17.9091 1H8.09091Z" stroke="#58508D" stroke-width="1.5"/>
                                                                    <path d="M15.7473 8.38463V12.3846C15.7473 12.5545 15.6095 12.6923 15.4397 12.6923C15.2698 12.6923 15.132 12.5545 15.132 12.3846V6.53848H13.2858V11.7692C13.2858 11.9391 13.148 12.0769 12.9781 12.0769C12.8083 12.0769 12.6704 11.9391 12.6704 11.7692V5.9231H10.8243V11.7692C10.8243 11.9391 10.6864 12.0769 10.5166 12.0769C10.3467 12.0769 10.2089 11.9391 10.2089 11.7692V7.15386H8.36273V14.8462L7.11658 13.1409C6.74735 12.5717 6.02673 12.3809 5.49873 12.7071C4.97258 13.0406 4.84212 13.768 5.20642 14.3354C5.20642 14.3354 7.21627 17.3772 8.07289 18.6794C8.9295 19.9816 10.3172 21 12.9123 21C17.2089 21 17.5935 17.6819 17.5935 16.6923C17.5935 15.7028 17.5935 8.38463 17.5935 8.38463H15.7473Z" fill="#58508D"/>
                                                                </svg>

                                                            </figure>
                                                            <em>On Hold</em>                                                 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/archive.svg"></figure>
                                                            <em>Archive</em> 
                                                        </template> 
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/Icon_user.svg"></figure>
                                                            <em>Assign</em>                                                 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="assign_sec">
                                                                <input type="text" placeholder="Enter Name" class="form-control" />
                                                                <div class="members_list_wrap">
                                                                    <div class="members_list">
                                                                        <h6>Added Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Pooja H</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Purnima Ramesh</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Piyush Shah</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Prabhas Rao</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/plus.svg"></em>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div class="members_list">
                                                                        <h6>Assigned Team Members</h6>
                                                                        <ul>
                                                                            <li>
                                                                                <div class="d-flex align-items-center">
                                                                                    <figure><img src="@/assets/images/user.png"></figure>
                                                                                    <figcaption>Rahul Sheety</figcaption>
                                                                                </div>
                                                                                <em><img src="@/assets/images/minus.svg"></em>
                                                                            </li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="members_list_actions">
                                                                    <button>CANCEL</button>
                                                                    <button>SAVE</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>

                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/reply.svg"></figure>
                                                            <em>Reply</em> 
                                                        </template>
                                                        <div class="tab_content">
                                                            <div class="reply_sec">
                                                                <div class="reply_list"></div>
                                                                <div class="reply_bottom">
                                                                    <ul class="reply_actions">
                                                                        <li><img src="@/assets/images/clip3.svg" ></li>
                                                                        <li><img src="@/assets/images/emoji.svg" ></li>
                                                                    </ul>
                                                                    <div class="reply_cnt">
                                                                        <input type="text" placeholder="Type Message" />
                                                                        <button><img src="@/assets/images/Polygon.svg" ></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </b-tab>
                                                    
                                                    <b-tab>
                                                        <template #title>
                                                            <figure><img src="@/assets/images/approve.png"></figure>
                                                            <em>Approve</em> 
                                                        </template> 
                                                    </b-tab>
                                                </b-tabs>
                                            </div>
                                            </div>
                                        </popper>
                                    </div>
                                </div>  
                            </div>
                            <div class="task-item">
                                <label><br/></label>
                                <div class="task-content "> 
                                    <div class="popper_cnt">
                                        <popper trigger="clickToOpen" :options="{placement: 'bottom'}">
                                            <button slot="reference" class="takeaction">
                                            Activity Log
                                            </button>
                                            <div class="popper">
                                                <div class="popper_head">
                                                    <h5>Activity Log</h5>
                                                </div>
                                                <div class="activity_table">
                                                    <table>
                                                        <thead>                                                        
                                                            <tr>
                                                                <th>actioned on</th>
                                                                <th>Action Type</th>
                                                                <th>TASK ASSIGNED BY</th>
                                                                <th>TASK ASSIGNED TO</th>
                                                                <th>comments</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>4th Nov ‘20 <span>01:04 am</span></td>
                                                                <td>Approved</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Raj Sinha</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>-</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3rd Nov ‘20<span>04:04 pm</span></td>
                                                                <td>Approved</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Sai Pallavi</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Raj Sinha</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>2nd Nov ‘20<span>10:15 am</span></td>
                                                                <td>Flag - Urgent</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Hema Dharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>1st Nov ‘20<span>11:04 am</span></td>
                                                                <td>Created</td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Rohit Sharma</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="activity_profile">
                                                                        <figure><img src="@/assets/images/user.png"></figure>
                                                                        <figcaption>Pooja Radhakrishna</figcaption>
                                                                    </div>
                                                                </td>
                                                                <td> 
                                                                    <div class="comments">Attached is Kanha budet 2020. Need your approval on the same for further <em>...</em></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </popper>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
        <b-modal ref="my-modal" id="attachments" centered hide-header hide-footer>
            <span class="close"  @click="hideModal"><span aria-hidden="true">&times;</span></span>
             <div class="modal-table">
                <table>
                    <thead>
                        <tr>
                            <td>Document Name</td>
                            <td>Document Type</td>
                            <td>ACTION</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div class="d-flex"><b-form-checkbox></b-form-checkbox>Plantation Draft</div>
                            </td>
                            <td><span>DOC</span></td>
                            <td><a href="#">DOWNLOAD</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex"><b-form-checkbox></b-form-checkbox>Budget plan</div>
                            </td>
                            <td><span>XSL</span></td>
                            <td><a href="#">DOWNLOAD</a></td>
                        </tr>
                        <tr>
                            <td>
                                <div class="d-flex"><b-form-checkbox></b-form-checkbox>Team Member list</div>
                            </td>
                            <td><span>PDF</span></td>
                            <td><a href="#">DOWNLOAD</a></td>
                        </tr>
                    </tbody>
                </table>
             </div>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect-inv";
import dateRange from '@/components/dateRange.vue'
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
  export default {
    components: {
        Multiselect,
        Popper,
        dateRange
    },
    data() {
      return {
        isActive: 'All',
        selected: null,
        categorySelected: 'All',
        subCategorySelected: 'All',
        TimeSelected: 'All',
        filteroptions: [
          { value: null, text: 'All' },
          { value: 'a', text: 'Option 1' },
          { value: 'b', text: 'Option 2' },
          { value: 'c', text: 'Option 3' },
          { value: 'd', text: 'Option 4',}
        ],
        options: ['list', 'of', 'options'],
        categoryOptions: ['All ', 'Publication & Media', 'Project', 'Administration' ,'People Connect'],
        subCategoryOptions: ['All ', 'Book', 'Interview', 'Vedio'],
        TimeOptions: ['All ', 'Time 1', 'Time 2', 'Time 3'],
      }
    },
     methods: {
      showModal() {
        this.$refs['my-modal'].show() 
      },
      hideModal() {
        this.$refs['my-modal'].hide() 
      },
      toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn') 
      },
     




      
    },
  }
</script>